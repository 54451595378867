/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "./store/store";
import "bootstrap-notify";
import jQuery from "jquery";
import VueToast from "vue-toast-notification";
import Buffer from "buffer";
// import VueMeta from 'vue-meta';


const Image = () => import("./components/lazyimage");
import "vue-toast-notification/dist/theme-sugar.css";
import DGA from "./dga";

window.dataLayer = window.dataLayer || [];


import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//https://www.npmjs.com/package/vue-material-design-icons
//icons at https://materialdesignicons.com/
const ChevronLeftIcon = () =>
  import(
        /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronLeft.vue"
  );
const ChevronRightIcon = () =>
  import(
        /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronRight.vue"
  );
const Account = () =>
  import(
        /* webpackChunkName: "material-icons" */ "vue-material-design-icons/AccountCircle.vue"
  );

import Cookies from "js-cookie";
import identity from "./services/identity";
import walletserve from "./services/walletserve";
import fix from "./services/fix";
import prag from "@/services/prag";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import bonusserve from "@/services/bonus";
import jpserve from "@/services/jpserve";

// adds pragmatic play games websockets to vue instance
Vue.prototype.DGA = DGA;

// you should remove this warning after you fix markets and place bet
Vue.config.silent = true;
Vue.use(VueToast);


Vue.filter('currency', function (amount) {
  if (amount == null) {
    amount = 0;
  }
  return (amount).toFixed(2).toLocaleString('en-US', {
    style: 'currency',
    currency: 'Ksh',
  });
})


Vue.filter('formatOdds',  function (x) {
  if (x === undefined) {
    return 1;
  }
  return parseFloat(x).toFixed(2);
})




Vue.component("arrow-left-icon", ChevronLeftIcon);
Vue.component("arrow-right-icon", ChevronRightIcon);
Vue.component("person-icon", Account);
Vue.component("lazy-image", Image);

const $ = jQuery;
Vue.prototype.jQuery = $;
const EventBus = new Vue();
Vue.prototype.EventBus = EventBus;
//Vue.prototype.Swal = VueSweetalert2;

Vue.use(VueRouter);
Vue.config.productionTip = true;
Vue.use(VueToast);
Vue.use(VueSweetalert2);
Vue.use(Buffer);
// Vue.use(VueMeta);


function notif(message, type) {
  $.notify(
    {
      icon: "add_alert",
      message: message,
    },
    {
      type: type,
      timer: 3000,
      placement: {
        from: "top",
        align: "center",
      },
    }
  );
}

Vue.mixin({
  methods: {
    /**
     * gets a random value from 0 to the supplied max
     * @param max
     * @returns {number}
     */
    getRandomInt: function (max) {
      return Math.floor(Math.random() * max);
    },
    resetFilters: function () {
      this.$store.dispatch("setHour", -1);
      this.$store.dispatch("setSportID", 1);
      this.$store.dispatch("setMarketID", 0);
      this.$store.dispatch("setCompetitionID", 0);
      this.$store.dispatch("setCategoryID", 0);
      this.$store.dispatch("setUpcomingDate", "");
      this.$store.dispatch("setCountryCode", "");
    },
    randomString: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    getUIValue: function (text) {
      var len = text.length;
      if (len <= parseInt(process.env.VUE_APP_BASE_LENGTH)) {
        return 0;
      }
      return parseInt(
        text.slice(parseInt(process.env.VUE_APP_BASE_LENGTH), len),
        parseInt(process.env.VUE_APP_BASE_RADIX)
      );
    },
    getBackendValue: function (text) {
      text = parseInt(text);
      return (
        this.randomString(parseInt(process.env.VUE_APP_BASE_LENGTH)) +
        "" +
        text.toString(parseInt(process.env.VUE_APP_BASE_RADIX))
      );
    },

    getClientID: function () {
      var prof = this.getProfile();
      var n = 10;

      if (!prof) {
        return (
          "anonymous-" +
          Math.floor(Math.random() * (9 * Math.pow(10, n))) +
          Math.pow(10, n)
        );
      }

      n = 5;
      return (
        "profile-" +
        prof.id +
        "-" +
        Math.floor(Math.random() * (9 * Math.pow(10, n))) +
        Math.pow(10, n)
      );
    },

    getOddID: function (match, market, outcome, specifier, alias) {
      var id =
        alias +
        "-match-" +
        match +
        "-market-" +
        market +
        "-outcome-" +
        outcome +
        "-specifier-" +
        specifier;
      id = id.replace(/\|/g, "-and-");
      id = id.replace(/=/g, "-eq-");
      id = id.replaceAll("+", "");
      return id;
    },

    lockOdds: function (
      element,
      producer_id,
      producer_status,
      match_id,
      market_id,
      outcome_id,
      specifier,
      status,
      active,
      odds
    ) {
      var oddID = this.getOddID(
        match_id,
        market_id,
        outcome_id,
        specifier,
        "odd"
      );
      var oddElement = document.getElementById(oddID);

      var oddDirection = this.getOddID(
        match_id,
        market_id,
        outcome_id,
        specifier,
        "direction"
      );
      var directionElement = document.getElementById(oddDirection);

      element.classList.remove("btn-odd");
      element.classList.remove("locked");
      element.classList.add("locked");
      element.classList.add("btn-odd");

      element.disabled = true;

      if (odds !== false) {
        oddElement.setAttribute("oddValue", odds);
      }

      if (status !== false) {
        oddElement.setAttribute("oddStatus", status);
      }

      if (active !== false) {
        oddElement.setAttribute("oddActive", active);
      }

      if (
        (odds === false || status === false || active === false) &&
        producer_id === 3
      ) {
        return;
      }

      oddElement.setAttribute("producerId", producer_id);
      oddElement.setAttribute("producerStatus", producer_status);

      directionElement.classList.remove("arrow");
      directionElement.classList.remove("green");
      directionElement.classList.remove("red");
      directionElement.classList.remove("up");
      directionElement.classList.remove("down");
      oddElement.textContent = "-";
      directionElement.textContent = "";
    },
    displayOdds: function (
      element,
      producer_id,
      producer_status,
      match_id,
      market_id,
      outcome_id,
      specifier,
      status,
      active,
      odds
    ) {
      var oddID = this.getOddID(
        match_id,
        market_id,
        outcome_id,
        specifier,
        "odd"
      );
      var oddElement = document.getElementById(oddID);

      var oddDirection = this.getOddID(
        match_id,
        market_id,
        outcome_id,
        specifier,
        "direction"
      );
      var directionElement = document.getElementById(oddDirection);

      element.classList.remove("locked");
      element.classList.remove("btn-odd");
      element.classList.add("btn-odd");
      element.disabled = false;

      var currentOdds = oddElement.getAttribute("oddValue");
      console.log("got currentOdds => " + currentOdds);

      if (odds === false) {
        odds = currentOdds;
      }

      if (status === false) {
        status = oddElement.getAttribute("oddStatus");
      }

      if (active === false) {
        active = oddElement.getAttribute("oddActive");
      }

      oddElement.setAttribute("oddStatus", status);
      oddElement.setAttribute("oddActive", active);
      oddElement.setAttribute("oddValue", odds);
      oddElement.setAttribute("producerId", producer_id);
      oddElement.setAttribute("producerStatus", producer_status);

      // remove all odd direction classes
      if (directionElement !== null) {
        directionElement.classList.remove("arrow");
        directionElement.classList.remove("green");
        directionElement.classList.remove("red");
        directionElement.classList.remove("up");
        directionElement.classList.remove("down");
        directionElement.classList.add("arrow");

        // add odd direction classes
        if (parseFloat(odds) > parseFloat(currentOdds)) {
          directionElement.classList.add("green");
          directionElement.classList.add("up");
        } else {
          directionElement.classList.add("red");
          directionElement.classList.add("down");
        }
      } else {
        console.log("directionElement is null from " + oddDirection);
      }

      // display odds
      //dom
      if (currentOdds !== odds) {
        var i = 0;
        var c = oddElement.getElementsByClassName("vue");
        for (i = 0; i < c; i++) {
          oddElement.getElementsByClassName("vue").item(i).remove();
        }

        c = oddElement.getElementsByClassName("dom");
        i = 0;

        for (i = 0; i < c; i++) {
          oddElement.getElementsByClassName("dom").item(i).textContent = odds;
        }

        console.log(
          "setting news odds from " +
          currentOdds +
          " to " +
          odds +
          " element " +
          oddID
        );
      }

      // check if producer 1 then act on producer status
      if (parseInt(producer_id) === 1) {
        if (parseInt(producer_status) === 0) {
          // producer down
          // lock odds
          this.lockOdds(
            element,
            producer_id,
            producer_status,
            match_id,
            market_id,
            outcome_id,
            specifier,
            status,
            active,
            odds
          );
        }
      }
    },
    async oddsUpdate(payload) {
      var vm = this;
      // console.log("PAAYYLLOOAADD--->>" + JSON.stringify(payload))

      if (Array.isArray(payload)) {
        this.jQuery.each(payload, function (k, v) {
          switch (v.type) {
            case "ODDS":
              var message = v.message;
              var match_id = parseInt(message.match_id);
              var market_id = parseInt(message.market_id);
              var outcome_id = message.outcome_id;
              var producer_id = parseInt(message.producer_id);
              var producer_status = message.producer_status;
              var specifier = message.specifier;
              var status = parseInt(message.status);
              var status_name = message.status_name;
              var btimestamp = message.timestamp;
              var odd_value = message.odd;
              var active = parseInt(message.active);

              var oddID = vm.getOddID(
                match_id,
                market_id,
                outcome_id,
                specifier,
                "odd"
              );
              vm.EventBus.$emit("odds:" + oddID, {
                status: status,
                active: active,
                odd: odd_value,
              });

              var delays = 0;
              if (message.processing_delays) {
                delays = message.processing_delays;
              } else {
                var currentDate = new Date();
                var timestamp = currentDate.getTime();
                delays = parseInt(timestamp) - parseInt(btimestamp);

                //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
              }

              console.log(
                v.event +
                " ==> market " +
                market_id +
                " outcome " +
                outcome_id +
                " status " +
                status_name +
                " Delays " +
                delays +
                "ms"
              );
              break;

            case "PRODUCER-STATUS":
              producer_id = parseInt(message.producer_id);
              producer_status = parseInt(message.producer_status);

              if (producer_id === 3) {
                // ignore prematch producer status events

                break;
              }

              //producer_status = producer_status === 0 ? 1 : 0;

              vm.EventBus.$emit("producer:status", {
                producer_status: producer_status,
                producer_id: producer_id,
              });

              // currentDate = new Date();
              //timestamp = currentDate.getTime();
              //console.log("Processing producer status time is "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
              break;

            case "PROFILE":
              var notification = message.notification;
              if (notification) {
                var type = notification.type;
                var title = notification.title;
                var msg = notification.message;

                if (type === "error") {
                  this.setError(title, msg);
                } else if (type === "success") {
                  this.setSuccess(title, msg);
                } else if (type === "warning") {
                  this.setWarning(title, msg);
                }
              }
              break;

            case "BET_STOP":
              match_id = parseInt(v.match_id);
              //btimestamp = parseInt(v.timestamp);
              //var timestamp_sent = parseInt(v.timestamp_sent);
              //currentDate = new Date();
              //timestamp = currentDate.getTime();
              vm.EventBus.$emit("betstop:match-" + match_id);

              break;

            case "MARKET_STATUS":
              match_id = parseInt(v.match_id);
              market_id = parseInt(v.market_id);
              btimestamp = parseInt(v.timestamp);
              status = parseInt(v.status);
              status_name = v.status_name;
              //timestamp_sent = parseInt(v.timestamp_sent);
              //currentDate = new Date();
              //timestamp = currentDate.getTime();
              vm.EventBus.$emit(
                "status:match-" + match_id + ":market-" + market_id,
                {
                  status: status,
                }
              );

              break;

            case "BETSLIP":
              message = v.message;
              match_id = parseInt(message.match_id);
              market_id = parseInt(message.market_id);
              outcome_id = message.outcome_id;
              producer_id = parseInt(message.producer_id);
              producer_status = message.producer_status;
              specifier = message.specifier;
              status = parseInt(message.status);
              status_name = message.status_name;
              //btimestamp = message.timestamp;
              odd_value = message.odd;
              active = parseInt(message.active);

              oddID = this.getOddID(
                match_id,
                market_id,
                outcome_id,
                specifier,
                "odd"
              );

              var oddsChangedPayload = {};

              oddsChangedPayload.id = oddID;
              oddsChangedPayload.odds = odd_value;
              oddsChangedPayload.status = status;
              oddsChangedPayload.active = active;
              oddsChangedPayload.previous_odds = odd_value;

              this.EventBus.$emit("odds:changed", oddsChangedPayload);

              delays = 0;
              if (message.processing_delays) {
                delays = message.processing_delays;
              } else {
                //currentDate = new Date();
                //timestamp = currentDate.getTime();
                //delays = parseInt(timestamp) - parseInt(btimestamp);
                //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
              }

              //if (market_id == 45 && outcome_id == 274)

              //console.log(payload.event+" ==> market "+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+delays+"ms");
              break;
          }
        });

        return;
      }

      switch (payload.type) {
        case "ODDS":
          var message = payload.message;
          var match_id = parseInt(message.match_id);
          var market_id = parseInt(message.market_id);
          var outcome_id = message.outcome_id;
          var producer_id = parseInt(message.producer_id);
          var producer_status = message.producer_status;
          var specifier = message.specifier;
          var status = parseInt(message.status);
          var odd_value = message.odd;
          var active = parseInt(message.active);

          var oddID = this.getOddID(
            match_id,
            market_id,
            outcome_id,
            specifier,
            "odd"
          );
          this.EventBus.$emit("odds:" + oddID, {
            status: status,
            active: active,
            odd: odd_value,
          });

          break;

        case "BETSLIP":
          message = payload.message;
          match_id = parseInt(message.match_id);
          market_id = parseInt(message.market_id);
          outcome_id = message.outcome_id;
          producer_id = parseInt(message.producer_id);
          producer_status = message.producer_status;
          specifier = message.specifier;
          status = parseInt(message.status);
          //status_name = message.status_name;
          //btimestamp = message.timestamp;
          odd_value = message.odd;
          active = parseInt(message.active);

          oddID = this.getOddID(
            match_id,
            market_id,
            outcome_id,
            specifier,
            "odd"
          );

          var oddsChangedPayload = {};

          oddsChangedPayload.id = oddID;
          oddsChangedPayload.odds = odd_value;
          oddsChangedPayload.status = status;
          oddsChangedPayload.active = active;
          oddsChangedPayload.previous_odds = odd_value;

          this.EventBus.$emit("odds:changed", oddsChangedPayload);

          break;

        case "PRODUCER-STATUS":
          producer_id = parseInt(message.producer_id);
          producer_status = parseInt(message.producer_status);

          if (producer_id === 3) {
            // ignore prematch producer status events

            break;
          }

          this.EventBus.$emit("producer:status", {
            producer_status: producer_status,
            producer_id: producer_id,
          });

          // currentDate = new Date();
          //timestamp = currentDate.getTime();
          //console.log("Processing producer status time is "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
          break;

        case "PROFILE":
          var notification = payload.message.notification;
          if (notification) {
            var type = notification.type;
            var title = notification.title;
            var msg = notification.message;

            if (type === "error") {
              this.setError(title, msg);
            } else if (type === "success") {
              this.setSuccess(title, msg);
            } else if (type === "warning") {
              this.setWarning(title, msg);
            }
          }
          break;

        case "LOGIN":
          var login_tag = this.getValue("login_tag");
          var current_login_tag = payload.message.login_tag;

          console.log(
            "got login_tag " +
            login_tag +
            " and current_login_tag " +
            current_login_tag
          );

          if (parseInt(login_tag) !== parseInt(current_login_tag)) {
            // console.log("This is where i crash A");
            this.setError(
              "Session Expired",
              "Your session on this device has expired"
            );
            this.logout();
          }

          break;

        case "BET_STOP":
          match_id = parseInt(payload.match_id);
          //btimestamp = parseInt(payload.timestamp);
          //var timestamp_sent = parseInt(payload.timestamp_sent);
          //currentDate = new Date();
          //timestamp = currentDate.getTime();
          this.EventBus.$emit("betstop:match-" + match_id);
          //console.log(payload.type+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms Waiting time "+(parseInt(timestamp) - parseInt(timestamp_sent))+"ms");
          break;

        case "MARKET_STATUS":
          match_id = parseInt(payload.match_id);
          market_id = parseInt(payload.market_id);
          //btimestamp = parseInt(payload.timestamp);
          status = parseInt(payload.status);
          //status_name = payload.status_name;
          //timestamp_sent = parseInt(payload.timestamp_sent);
          //currentDate = new Date();
          //timestamp = currentDate.getTime();
          this.EventBus.$emit(
            "status:match-" + match_id + ":market-" + market_id,
            {
              status: status,
            }
          );
        //console.log(payload.type+" ==> matchID "+match_id+" marketID "+market_id+" ==> "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms Waiting time "+(parseInt(timestamp) - parseInt(timestamp_sent))+"ms");
      }
    },
    async uxMarketUpdate(payload) {
      var vm = this;

      switch (payload.event_type) {
        case "producer_status":
          var producer_id = parseInt(payload.message.producer_id);
          var producer_status = parseInt(payload.message.producer_status);

          // console.log(
          //   "Got producerID " + producer_id + " status " + producer_status
          // );

          this.EventBus.$emit("producer:status", {
            producer_status: producer_status,
            producer_id: producer_id,
          });
          break;

        case "odds_change":
          var message = payload;
          var match_id = parseInt(message.match_id);
          //var btimestamp = parseInt(payload.betradar_timestamp);

          var markets = message.markets;

          vm.jQuery.each(markets, function (k, v) {
            var market_id = parseInt(v.market_id);
            var status = v.status;
            var specifier =
              v.specifier === undefined || v.specifier === null
                ? ""
                : v.specifier;
            var outcome = v.outcome;

            if (outcome.length === 0) {
              var topic = "status:match-" + match_id + ":market-" + market_id;
              vm.EventBus.$emit(topic, {
                status: status,
              });
            } else {
              vm.jQuery.each(outcome, function (key, val) {
                var outcome_id = val.outcome_id;
                var active = parseInt(val.active);
                var odd_value = val.odd;
                var previous_odds = val.previous_odds;
                var odd_id = val.odd_id;
                status = parseInt(val.status);

                var ux = {
                  status: status,
                  active: active,
                  odd: odd_value,
                  previous_odds: previous_odds,
                  odd_id: odd_id,
                };

                var oddID = vm.getOddID(
                  match_id,
                  market_id,
                  outcome_id,
                  specifier,
                  "odd"
                );
                var topic = "odds:" + oddID;
                vm.EventBus.$emit(topic, ux);
              });
            }
          });

          // var currentDate = new Date();
          // var timestamp = parseInt(currentDate.getTime());
          console.log(
            payload.event +
            " ==> " +
            match_id +
            " Delays " +
            (timestamp - btimestamp) +
            "ms"
          );
          break;

        case "bet_stop":
          message = payload;
          match_id = parseInt(payload.match_id);
          // btimestamp = parseInt(payload.betradar_timestamp);
          // currentDate = new Date();
          // timestamp = currentDate.getTime();
          vm.EventBus.$emit("betstop:match-" + match_id);

          //console.log(payload.event+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp)));
          break;

        default:
          match_id = parseInt(payload.match_id);
          // btimestamp = parseInt(payload.timestamp);
          vm.EventBus.$emit("match:reload:" + match_id, payload);
        //currentDate = new Date();
        // timestamp = parseInt(currentDate.getTime());
        // console.log(payload.event + " ==> " + match_id + " Delays " + (timestamp - btimestamp) + "ms");
      }

      /*
      switch (payload.event) {

          case "producer_status":

              var producer_id = parseInt(payload.message.producer_id);
              var producer_status = parseInt(payload.message.producer_status);

              console.log('Got producerID ' + producer_id + ' status ' + producer_status)

              this.EventBus.$emit('producer:status', {
                  producer_status: producer_status,
                  producer_id: producer_id
              });

              break;

          case 'odds_change':
              var message = payload;
              var match_id = parseInt(message.match_id);
              //var producer_id = parseInt(message.producer_id);
              //var producer_status = message.producer_status;
              var btimestamp = message.betradar_timestamp;

              var markets = message.markets;
              $.each(markets, function (k, v) {

                  var market_id = parseInt(v.market_id);
                  var status = parseInt(v.status);
                  var status_name = v.status_name;
                  var specifier = v.specifiers;

                  var outcome = v.outcome;

                  if(outcome.length === 0 ) {

                      vm.EventBus.$emit('status:match-'+match_id+':market-'+market_id,{
                          status: status
                      });

                      var currentDate = new Date();
                      var timestamp = currentDate.getTime();
                      if(market_id === 1)
                          console.log(payload.event+" ==> "+match_id+"#"+market_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");

                  } else {

                      $.each(outcome, function (key, val) {

                          var outcome_id = val.outcome_id;
                          var active = parseInt(val.active);
                          var odd_value = val.odd;
                          var ux = {
                              status: status,
                              active:active,
                              odd: odd_value
                          }

                          var oddID = vm.getOddID(match_id,market_id,outcome_id,specifier,'odd');
                          vm.EventBus.$emit('odds:'+oddID,ux);

                          var currentDate = new Date();
                          var timestamp = currentDate.getTime();
                          if(market_id === 1) {
                              console.log(payload.event + " ==> " + match_id + "#" + market_id + " outcome " + outcome_id + " status " + status_name + " odds ==> " + odd_value + " Delays " + (parseInt(timestamp) - parseInt(btimestamp)) + "ms");
                              console.log('ODD ID odds:'+oddID+' payload '+JSON.stringify(ux,undefined,2))
                          }

                      });
                  }

              });
              vm.EventBus.$emit('match:reload:'+match_id);

              break;

          case 'bet_stop':
              message = payload;
              match_id = parseInt(payload.match_id);
              btimestamp = parseInt(payload.betradar_timestamp);
              var currentDate = new Date();
              var timestamp = currentDate.getTime();
              vm.EventBus.$emit('betstop:match-'+match_id);
              vm.EventBus.$emit('match:reload:'+match_id);

              console.log(payload.event+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp)));
              break;
      }
      */
    },
    async uxUpdate(payload) {
      var vm = this;

      var procesed = false;

      switch (payload.event) {
        case "producer_status":
          procesed = true;
          var producer_id = parseInt(payload.message.producer_id);
          var producer_status = parseInt(payload.message.producer_status);

          console.log(
            "Got producerID " + producer_id + " status " + producer_status
          );

          this.EventBus.$emit("producer:status", {
            producer_status: producer_status,
            producer_id: producer_id,
          });
          break;

        case "odds_change":
          procesed = true;
          var message = payload;
          var match_id = parseInt(message.match_id);
          //var btimestamp = parseInt(payload.timestamp);

          var markets = message.market;

          vm.jQuery.each(markets, function (k, v) {
            var market_id = parseInt(v.market_id);
            var status = v.status;
            var specifier =
              v.specifier === undefined || v.specifier === null
                ? ""
                : v.specifier;

            var outcome = v.outcome;

            if (outcome.length === 0) {
              var topic = "status:match-" + match_id + ":market-" + market_id;
              vm.EventBus.$emit(topic, {
                status: status,
              });
            } else {
              vm.jQuery.each(outcome, function (key, val) {
                var outcome_id = val.outcome_id;
                var active = parseInt(val.active);
                var odd_value = val.odds;
                var previous_odds = val.previous_odds;
                var odd_id = val.odd_id;
                status = parseInt(val.status);

                var ux = {
                  status: status,
                  active: active,
                  odds: odd_value,
                  previous_odds: previous_odds,
                  odd_id: odd_id,
                };

                var oddID = vm.getOddID(
                  match_id,
                  market_id,
                  outcome_id,
                  specifier,
                  "odd"
                );
                var topic = "odds:" + oddID;
                vm.EventBus.$emit(topic, ux);

                // if(market_id == 1) {

                // console.log("Published to topic ===================", topic,);
                // console.log("The payload is with payload",JSON.stringify( ux))

                // }

                topic = "odds:" + odd_id;
                vm.EventBus.$emit(topic, ux);
              });
            }
          });

          vm.EventBus.$emit("match:reload:" + match_id, payload);

          break;

        case "bet_stop":
          procesed = true;
          message = payload;
          match_id = parseInt(payload.match_id);
          //btimestamp = parseInt(payload.timestamp);
          //currentDate = new Date();
          //timestamp = parseInt(currentDate.getTime());
          vm.EventBus.$emit("betstop:match-" + match_id);
          vm.EventBus.$emit("match:reload:" + match_id, payload);

          break;

        default:
          match_id = parseInt(payload.match_id);
          //btimestamp = parseInt(payload.timestamp);
          vm.EventBus.$emit("match:reload:" + match_id, payload);
        //currentDate = new Date();
        //timestamp = parseInt(currentDate.getTime());
      }
      /*
      switch (payload.event) {

          case "producer_status":

              var producer_id = parseInt(payload.message.producer_id);
              var producer_status = parseInt(payload.message.producer_status);

              console.log('Got producerID ' + producer_id + ' status ' + producer_status)

              this.EventBus.$emit('producer:status', {
                  producer_status: producer_status,
                  producer_id: producer_id
              });

              break;

          case 'odds_change':
              var message = payload;
              var match_id = parseInt(message.match_id);
              //var producer_id = parseInt(message.producer_id);
              //var producer_status = message.producer_status;
              var btimestamp = message.betradar_timestamp;

              var markets = message.markets;
              $.each(markets, function (k, v) {

                  var market_id = parseInt(v.market_id);
                  var status = parseInt(v.status);
                  var status_name = v.status_name;
                  var specifier = v.specifiers;

                  var outcome = v.outcome;

                  if(outcome.length === 0 ) {

                      vm.EventBus.$emit('status:match-'+match_id+':market-'+market_id,{
                          status: status
                      });

                      var currentDate = new Date();
                      var timestamp = currentDate.getTime();
                      if(market_id === 1)
                          console.log(payload.event+" ==> "+match_id+"#"+market_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");

                  } else {

                      $.each(outcome, function (key, val) {

                          var outcome_id = val.outcome_id;
                          var active = parseInt(val.active);
                          var odd_value = val.odd;
                          var ux = {
                              status: status,
                              active:active,
                              odd: odd_value
                          }

                          var oddID = vm.getOddID(match_id,market_id,outcome_id,specifier,'odd');
                          vm.EventBus.$emit('odds:'+oddID,ux);

                          var currentDate = new Date();
                          var timestamp = currentDate.getTime();
                          if(market_id === 1) {
                              console.log(payload.event + " ==> " + match_id + "#" + market_id + " outcome " + outcome_id + " status " + status_name + " odds ==> " + odd_value + " Delays " + (parseInt(timestamp) - parseInt(btimestamp)) + "ms");
                              console.log('ODD ID odds:'+oddID+' payload '+JSON.stringify(ux,undefined,2))
                          }

                      });
                  }

              });
              vm.EventBus.$emit('match:reload:'+match_id);

              break;

          case 'bet_stop':
              message = payload;
              match_id = parseInt(payload.match_id);
              btimestamp = parseInt(payload.betradar_timestamp);
              var currentDate = new Date();
              var timestamp = currentDate.getTime();
              vm.EventBus.$emit('betstop:match-'+match_id);
              vm.EventBus.$emit('match:reload:'+match_id);

              console.log(payload.event+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp)));
              break;
      }
      */

      if (!procesed) {
        // console.log(JSON.stringify(payload));

        switch (payload.type) {
          case "PROFILE":
            var notification = payload.message.notification;

            if (notification) {
              var type = notification.type;
              var title = notification.title;
              var msg = notification.message;

              if (type === "error") {
                this.setError(title, msg);
              } else if (type === "success") {
                this.setSuccess(title, msg);
              } else if (type === "warning") {
                this.setWarning(title, msg);
              }

              this.reloadProfile();
            }
            break;

          case "LOGIN":
            var login_tag = this.getValue("login_tag");
            var current_login_tag = payload.message.login_tag;
            if (parseInt(login_tag) !== parseInt(current_login_tag)) {
              // console.log("This is where i crash B");
              this.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              this.logout();
            }

            break;
        }
      }
    },
    async uxUpdate1(payload) {
      var vm = this;

      switch (payload.event_type) {
        case "producer_status":
          var producer_id = parseInt(payload.message.producer_id);
          var producer_status = parseInt(payload.message.producer_status);

          // console.log(
          //   "Got producerID " + producer_id + " status " + producer_status
          // );

          this.EventBus.$emit("producer:status", {
            producer_status: producer_status,
            producer_id: producer_id,
          });
          break;

        case "odds_change":
          var message = payload;
          var match_id = parseInt(message.match_id);
          //var btimestamp = parseInt(payload.betradar_timestamp);

          var markets = message.markets;

          vm.jQuery.each(markets, function (k, v) {
            var market_id = parseInt(v.market_id);
            var status = v.status;
            var specifier =
              v.specifiers === undefined || v.specifiers === null
                ? ""
                : v.specifiers;
            var outcome = v.outcome;
            //var status_name = v.status_name;

            if (outcome.length === 0) {
              var topic = "status:match-" + match_id + ":market-" + market_id;
              vm.EventBus.$emit(topic, {
                status: status,
              });
            } else {
              vm.jQuery.each(outcome, function (key, val) {
                var outcome_id = val.outcome_id;
                var active = parseInt(val.active);
                var odd_value = val.odd;
                var previous_odds = val.previous_odds;
                var odd_id = val.odd_id;
                status = parseInt(val.status);
                var oddID = vm.getOddID(
                  match_id,
                  market_id,
                  outcome_id,
                  specifier,
                  "odd"
                );

                var ux = {
                  status: status,
                  active: active,
                  odd: odd_value,
                  previous_odds: previous_odds,
                  odd_id: oddID,
                };

                var topic = "odds:" + oddID;
                vm.EventBus.$emit(topic, ux);

                topic = "odds:" + odd_id;
                vm.EventBus.$emit(topic, ux);
              });
            }
          });

          vm.EventBus.$emit("match:reload:" + match_id, payload);
          //var currentDate = new Date();
          //var timestamp = parseInt(currentDate.getTime());

          break;

        case "bet_stop":
          message = payload;
          match_id = parseInt(payload.match_id);
          //btimestamp = parseInt(payload.betradar_timestamp);
          //currentDate = new Date();
          //timestamp = currentDate.getTime();
          vm.EventBus.$emit("betstop:match-" + match_id);
          vm.EventBus.$emit("match:reload:" + match_id, payload);

          break;

        default:
          match_id = parseInt(payload.match_id);
          //btimestamp = parseInt(payload.betradar_timestamp);
          vm.EventBus.$emit("match:reload:" + match_id, payload);
        //currentDate = new Date();
        //timestamp = parseInt(currentDate.getTime());
      }
    },
    async systemNotification(payload) {
      switch (payload.type) {
        case "PROFILE":
          var notification = payload.message.notification;

          if (notification) {
            var type = notification.type;
            var title = notification.title;
            var msg = notification.message;
            var delivery =
              notification.delivery === undefined ? "" : notification.delivery;

            if (delivery !== "background") {
              if (type === "error") {
                this.setError(title, msg);
              } else if (type === "success") {
                this.setSuccess(title, msg);
              } else if (type === "warning") {
                this.setWarning(title, msg);
              }
            }

            this.reloadProfile();
          }
          break;

        case "LOGIN":
          var login_tag = this.getValue("login_tag");
          var current_login_tag = payload.message.login_tag;
          if (parseInt(login_tag) !== parseInt(current_login_tag)) {
            // console.log("This is where i crash C");
            this.setError(
              "Session Expired",
              "Your session on this device has expired"
            );
            this.logout();
          }

          break;
      }
    },

    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },

    getMB8Tokens: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_JACKPOT_URL + "/mb8/balance";

      // Get the profile from the store or any other source
      var profile = this.getProfile();

      // Check if the user is logged in
      var isLoggedIn = profile && profile.a;

      if (isLoggedIn) {
        jpserve
          .get(path, {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {
            vm.loading = "";
            var mb8balance = res.data.balance;
            var expiry = res.data.expiry_date;

            // Set the MB8 balance and expiry
            this.setMB8({
              mb8balance: mb8balance,
              expiry: expiry
            });
          })
          .catch((err) => {
            vm.loading = "";
            if (err.response) {
              // console.log(JSON.stringify(err.request));
            } else {
              vm.setError(
                "Network Error",
                "Check your network connection and try again"
              );
              // console.log(JSON.stringify(err));
            }
          });
      }
    },

    setMB8Tokens(mb8balance, expiry) {
      this.mb8balance = mb8balance;
      this.EventBus.$emit('mb8:updated', { mb8balance });
    },

    // getBonus() {
    //   var m = this.getProfile();
    //   var p = m.a;
    //   this.buttonClicked = true;
    //   if (!p) {
    //     return;
    //   }

    //   var path = process.env.VUE_APP_BASE_BONUS_URL + "/bonus/balance";

    //   bonusserve
    //     .get(path, {
    //       headers: {
    //         "api-key": this.getAuth(),
    //       },
    //     })
    //     .then((res) => {
    //       // console.log("Response Bonus", res)
    //       var x = res.data.balance;
    //       var y = res.data.pending_activation;
    //       var bonus = x + y;

    //       // console.log("Bonus Balance", bonus)
    //       var vm = this;
    //       vm.EventBus.$emit("init:mqtt");
    //       this.$store.dispatch("setBonus", bonus);

    //       this.setBonus(bonus);
    //     })
    //     .catch((err) => {
    //       console.log("Error", err)
    //       this.loading = "";
    //       if (err && err.response) {
    //         this.setError("Network Error", "Check your network connection and try again");
    //       } else {
    //         this.setError("Network Error", "Check your network connection and try again");
    //       }

    //     });
    // },

    setBonus(bonus) {
      // Set the bonus value
      this.bonus = bonus;
      this.EventBus.$emit('bonus:updated', bonus);
    },

    getProfile: function () {
      var authData = Cookies.get('a');
      var profileData = Cookies.get('i');

      if (authData && profileData) {
        try {
          var auth = authData.trim();
          var profile = JSON.parse(profileData);

          if (profile && Object.keys(profile).length > 0) {
            // Emit auth and balance separately
            this.EventBus.$emit('profile:auth', { a: auth });
            this.EventBus.$emit('profile:data', {
              id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name,
              msisdn: profile.msisdn,
              login_tag: profile.login_tag,
              new_profile: profile.new_profile,
              referral_code: profile.referral_code,
            });

            this.EventBus.$emit('profile:balance', { b1: profile.b1 });

            return {
              a: auth,
              id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name,
              msisdn: profile.msisdn,
              login_tag: profile.login_tag,
              new_profile: profile.new_profile,
              referral_code: profile.referral_code,
              b1: profile.b1,
            };
          }
        } catch (err) {
          console.log(err.message);
          return false;
        }
      }

      return false;
    },

    setProfile: function (profile) {
      Cookies.set("i", JSON.stringify(profile), { expires: 2, secure: false });
      this.EventBus.$emit("profile:balance", {
        b1: profile.b1,
        id: profile.id,
        first_name: profile.first_name,
        last_name: profile.last_name,
        new_profile: profile.new_profile,
        msisdn: profile.msisdn,
        referral_code: profile.referral_code,
      });
    },

    setAuth: function (auth) {
      Cookies.set("a", auth, { expires: 2, secure: false });
    },

    getAuth: function () {
      var p = Cookies.get("a");

      if (p) {
        if (p === "undefined") {
          return false;
        }

        try {
          var data = p.trim();
          if (data.length > 0) {
            return data;
          }
        } catch (err) {
          return false;
        }
      }

      return false;
    },

    removeProfile: function () {
      Cookies.remove("i");
    },
    removeAuth: function () {
      Cookies.remove("a");
    },

    // sendProfilePayload: function (payload) {
    //   console.log("Sending profile payload:", payload);
    // },

    reloadProfile: function () {
      var profile = this.getProfile();
      var vm = this;

      if (!profile) {
        return;
      }

      var path = process.env.VUE_APP_BASE_WALLET_URL + '/balance';

      walletserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          const updatedProfileData = res.data;

          vm.EventBus.$emit("init:mqtt");

          var login_tag = this.getValue("login_tag");

          var current_login_tag = profile.login_tag;

          if (parseInt(login_tag) !== parseInt(current_login_tag)) {
            vm.setError(
              "Session Expired",
              "Your session on this device has expired"
            );
            vm.logout();
          } else {
            profile.b1 = updatedProfileData.b1;
            // store in vuex
            this.$store.dispatch("setProfileBalance", updatedProfileData.b1);
            vm.setProfile(profile);
          }
          //console.log("LogTag--->", current_login_tag);

          //profile.b1 = updatedProfileData.b1;

          //vm.setProfile(profile);
        })
        .catch((err) => {
          console.log("Error Caught:", err);
          if (err.response) {
            if (parseInt(err.response.status) === 401) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              console.log("Session expired. Logging out...");
              vm.logout();
            } else if (parseInt(err.response.status) === 400) {
              vm.setError("Bad Request");
            } else {
              vm.setError("Failed", "An unexpected error occurred.");
            }
          } else {
            console.log("Network error or no response received.");
          }
        });
    },

    isMobile: function () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isDesktop: function () {
      return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    mysqlDate: function (now) {
      //YYYY-MM-DD
      var yyyy = now.getFullYear();
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var mm = months[now.getMonth()];
      var dt = now.getDate();
      var dd = dt > 9 ? dt : "0" + dt;
      return yyyy + "-" + mm + "-" + dd;
    },
    getToday: function () {
      return this.mysqlDate(new Date());
    },
    getTomorrow: function () {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return this.mysqlDate(date);
    },
    daysFromNow: function (days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return this.mysqlDate(date);
    },
    getDayOfWeek: function (date) {
      var now = new Date(date);
      // console.log("GOT date as " + now);

      var weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return weeks[now.getDay()];
    },
    getLongDayOfWeek: function (date) {
      var now = new Date(date);
      // console.log("GOT date as " + now);

      var weeks = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return weeks[now.getDay()];
    },
    formatMatchDate: function (date) {
      if (
        date === false ||
        date === undefined ||
        date === "" ||
        date === null
      ) {
        return "";
      }

      var res = date.replace(" ", "T");
      var d = new Date(res);
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var mon = months[d.getMonth()];
      var dt = d.getDate();
      var day = dt > 9 ? dt : "0" + dt;
      var hr = d.getHours();
      var hour = hr > 9 ? hr : "0" + hr;
      var min = d.getMinutes();
      var minutes = min > 9 ? min : "0" + min;
      var formated = day + "/" + mon + ", " + hour + ":" + minutes;
      return formated;
    },
    timeToStart: function (date) {
      if (
        date === false ||
        date === undefined ||
        date === "" ||
        date === null
      ) {
        return "";
      }

      var res = date.replace(" ", "T");
      var d = new Date(res);
      var now = new Date();

      if (now > d) {
        return "";
      }

      var seconds = (d - now) / 1000;

      if (seconds < 60) {
        // console.log(
        //   "GOT difference between " +
        //     date +
        //     " and now " +
        //     now +
        //     " is " +
        //     seconds +
        //     "s "
        // );
        return seconds + " Sec";
      }

      var minutes = parseInt(seconds / 60);
      // console.log(
      //   "GOT difference between " +
      //     date +
      //     " and now " +
      //     now +
      //     " is " +
      //     minutes +
      //     "min"
      // );

      return minutes + " Min";
    },
    formatCurrency: function (num) {
      if (
        num === false ||
        isNaN(parseFloat(num)) ||
        isNaN(num) ||
        num === undefined ||
        num === "" ||
        num === null
      ) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
    getSportIcon: function (sport_id) {
      if (sport_id === undefined) {
        return "";
      }

      if (typeof sport_id === String) {
        //sport_id = sport_id.replaceAll("\"", "").trim()
      }

      return (
        process.env.VUE_APP_ASSETS_BASE_URL +
        "/img/sport-icons/" +
        parseInt(sport_id) +
        ".png"
      );
    },
    getSoccerIcon: function () {
      return process.env.VUE_APP_ASSETS_BASE_URL + "/img/sport-icons/1.png";
    },
    showMenu: function () {
      document.getElementById("menu-init").click();
      //this.bestlip_visible = true;
    },
    copyToClipboard: function (text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    toTop: function () {
      window.scrollTo(0, 0);
    },
    getURL: function (path) {
      if (path.length > 0) {
        if (path.substring(0, 1) == "/") {
          // remove the first /
          path = path.substring(1);
        }
      }

      return process.env.VUE_APP_ASSETS_BASE_URL + path;
    },
    saveObject: function (key, value) {
      value = JSON.stringify(value);

      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },
    setValue: function (key, value) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },
    getObject: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);

        if (post === undefined) {
          return false;
        }

        return JSON.parse(post);
      } else {
        return JSON.parse(this.getCookie(key));
      }
    },

    shortenName: function (name, characters) {
      if (name == undefined) {
        return name;
      }

      name = name.trim();

      if (name.length <= characters) {
        return name;
      }

      return name.substring(0, characters) + "...";
    },

    getValue: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);
        if (post == "undefined") {
          return "";
        }

        return post;
      } else {
        return this.getCookie(key);
      }
    },

    removeObject: function (key) {
      if (typeof Storage !== "undefined") {
        localStorage.removeItem(key);
      } else {
        document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    },

    getCookie: function (ckey) {
      var key = ckey + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(key) == 0) {
          return c.substring(key.length, c.length);
        }
      }
      return "";
    },

    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    addPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks,
      live
    ) {

      // console.log("BETSLIP ADD PICKS---->" + JSON.stringify(picks, undefined, 2))
      var producer_id = picks.producer_id;
      var producer_status = picks.producer_status;
      var status = picks.status;
      var active = picks.active;

      // console.log("Picksssssss" + JSON.stringify(picks));

      /*console.log("ACTIVE---->" + active)

      console.log("Picks", picks);*/

      //Remove this when PRODUCER ID AND STATUS HAS BEEN RESOLVED - NOTE

      if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
        console.log(
          "exiting due to producer_id#" +
          producer_id +
          " producer_status#" +
          producer_status
        );
        return;
      }

      if (parseInt(active) === 0) {
        console.log("exiting due to active#" + active + " status#" + status);
        return;
      }

      // if (parseInt(active) === 0 || parseInt(status) === 0) {
      //   console.log("exiting due to active#" + active + " status#" + status);
      //   return;
      // }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("bslip");
      // console.log("Old betslip ---->" + JSON.stringify(bSlip, undefined, 2))

      // eslint-disable-next-line no-constant-condition
      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "odd"
      );
      picks.id2 = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "boosted"
      );

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 29) {
        this.setError("Error", "Maximum number of games reached");
        return;
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      // console.log("updated betslip ---->" + JSON.stringify(bt, undefined, 2))

      this.saveObject("bslip", bt);
      this.autoRefreshUI("addPick");
    },

    addJackpotPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks
    ) {
      var status = picks.status;
      var active = picks.active;

      if (parseInt(active) === 0 || parseInt(status) !== 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        //return
      }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("jslip");

      // eslint-disable-next-line no-constant-condition
      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "jpodd"
      );

      // console.log('picked id '+picks.id);

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);

      // console.log("Number of selected odds: " + bt.length);

      this.autoRefreshJackpotUI("addJackpotPick");

      // console.log("After selection: odd_id=" + odd_id + ", isunselect=" + isunselect);
    },

    addFreebetPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks
    ) {
      var status = picks.status;
      var active = picks.active;

      if (parseInt(active) === 0 || parseInt(status) !== 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        //return
      }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("jslip");

      // eslint-disable-next-line no-constant-condition
      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "jpodd"
      );

      // console.log('picked id '+picks.id);

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);

      // console.log("Number of selected odds: " + bt.length);

      this.autoRefreshFreebetUI("addFreebetPick");

      // console.log("After selection: odd_id=" + odd_id + ", isunselect=" + isunselect);
    },

    /**
     *
     * @param sport_id
     * @param tournament_id
     * @param market_name
     * @param market_id
     * @param tournament_name
     * @param category_name
     * @param picks
     */
    addOutrightPick: function (
      sport_id,
      tournament_id,
      market_name,
      market_id,
      tournament_name,
      category_name,
      picks
    ) {
      // console.log("at addOutrightPick ");

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("oslip");

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = tournament_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.tournament_name = tournament_name;
      picks.category_name = category_name;

      picks.id = this.getOddID(
        tournament_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "outright"
      );

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === tournament_id) {
              delete bSlip[k];
            }
          }
        });
      }

      // console.log("GOT length of isunselect " + isunselect);

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshOutrightUI("addOutrightPick");
    },

    updateOdd: function (odd_id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (parseInt(v.odd_id) === parseInt(odd_id)) {
          v.odd = odds;
          v.odds = odds;

          if (parseInt(v.active) !== parseInt(active)) {
            hasChanged = true;
          }

          if (parseInt(v.status) !== parseInt(status)) {
            hasChanged = true;
          }

          v.active = active;
          v.status = status;
          v.previous_odds = previous_odds;

          console.log(
            "previous_odds " +
            previous_odds +
            " new odds " +
            odds +
            " id " +
            v.odd_id +
            " received "
          );
        }

        bt.push(v);
        //}
      });

      if (hasChanged) {
        this.saveObject("bslip", bt);
        this.autoRefreshUI("updateOdd");
      }
      // }
    },

    updateJackpotOdd: function (id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          if (v.id === id) {
            v.odd = odds;
            v.odds = odds;

            if (parseInt(v.active) !== parseInt(active)) {
              hasChanged = true;
            }

            if (parseInt(v.status) !== parseInt(status)) {
              hasChanged = true;
            }

            v.active = active;
            v.status = status;
            v.previous_odds = previous_odds;
          }

          bt.push(v);
        }
      });

      if (hasChanged) {
        this.saveObject("jslip", bt);
        this.autoRefreshJackpotUI("updateJackpotOdd");
      }
      // }
    },

    updateOutrightOdd: function (id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          if (v.id === id) {
            v.odd = odds;
            v.odds = odds;

            if (parseInt(v.active) !== parseInt(active)) {
              hasChanged = true;
            }

            if (parseInt(v.status) !== parseInt(status)) {
              hasChanged = true;
            }

            v.active = active;
            v.status = status;
            v.previous_odds = previous_odds;
          }

          bt.push(v);
        }
      });

      if (hasChanged) {
        this.saveObject("oslip", bt);
        this.autoRefreshOutrightUI("updateOutrightOdd");
      }
      // }
    },

    hasSuspendedPicks: function () {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedJackpotPicks: function () {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedFreebetPicks: function () {
      var bSlip = this.getObject("fslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedOutrightPicks: function () {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    removeSuspendedPicks: function () {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI("removeSuspendedPicks");
    },

    removeSuspendedJackpotPicks: function () {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("jslip", bt);
      this.autoRefreshJackpotUI("removeSuspendedJackpotPicks");
    },

    removeSuspendedOutrightPicks: function () {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshOutrightUI("removeSuspendedPicks");
    },

    computeWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#betslip-footer").addClass("hidden");
        $(".slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      // var winnings = total;
      //var tax = 20 * (winnings - stake) / 100;
      //net = (winnings - tax);

      $("#possible_win").html(winning.toFixed(2).toLocaleString());
      $("#stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#bonus").html(bonus.toFixed(2).toLocaleString());
      $("#net_win").html(cashout.toFixed(2).toLocaleString());
      $("#win").html(cashout.toFixed(2).toLocaleString());
      $("#total_odd_value").html(parseFloat(odds).toFixed(2).toLocaleString());
      $("#withold_tax").html(withholdingTax.toFixed(2).toLocaleString());
    },
    computeJackpotWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#jackpot-betslip-footer").addClass("hidden");
        $(".jackpot-slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      // var winnings = total;
      //var tax = 20 * (winnings - stake) / 100;
      //net = (winnings - tax);

      $("#jackpot-possible_win").html(winning.toFixed(2).toLocaleString());
      $("#jackpot-stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#jackpot-excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#jackpot-bonus").html(bonus.toFixed(2).toLocaleString());
      $("#jackpot-net_win").html(cashout.toFixed(2).toLocaleString());
      $("#jackpot-win").html(cashout.toFixed(2).toLocaleString());
      $("#jackpot-total_odd_value").html(
        parseFloat(odds).toFixed(2).toLocaleString()
      );
      $("#jackpot-withold_tax").html(
        withholdingTax.toFixed(2).toLocaleString()
      );
    },

    computeOutrightWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#outright-betslip-footer").addClass("hidden");
        $(".outright-slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      $("#outright-possible_win").html(winning.toFixed(2).toLocaleString());
      $("#outright-stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#outright-excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#outright-bonus").html(bonus.toFixed(2).toLocaleString());
      $("#outright-net_win").html(cashout.toFixed(2).toLocaleString());
      $("#outright-win").html(cashout.toFixed(2).toLocaleString());
      $("#outright-total_odd_value").html(
        parseFloat(odds).toFixed(2).toLocaleString()
      );
      $("#outright-withold_tax").html(
        withholdingTax.toFixed(2).toLocaleString()
      );
    },

    checkSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#betslip-footer").addClass("hidden");
        $(".slip-counter").html("0");
        $("#slip_c").html("0");
        //$("#counter").html("0");
      } else {
        $("#betslip-footer").removeClass("hidden");

        if (slips > 30) {
          $("#slip_c").html(30);
          $(".slip-counter").html(30);
        } else {
          $("#slip_c").html(slips);
          $(".slip-counter").html(slips);
        }
      }
    },

    checkJackpotSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#jackpot-betslip-footer").addClass("hidden");
        $(".jackpot-slip-counter").html("0");
        $("#jackpot-slip_c").html("0");
        //$("#counter").html("0");
      } else {
        $("#jackpot-betslip-footer").removeClass("hidden");

        if (slips > 19) {
          $("#jackpot-slip_c").html(19);
          $(".jackpot-slip-counter").html(19);
        } else {
          $("#jackpot-slip_c").html(slips);
          $(".jackpot-slip-counter").html(slips);
        }
      }
    },

    checkOutrightSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#outright-betslip-footer").addClass("hidden");
        $(".outright-slip-counter").html("0");
        $("#outright-slip_c").html("0");
      } else {
        $("#outright-betslip-footer").removeClass("hidden");

        if (slips > 19) {
          $("#outright-slip_c").html(19);
          $(".outright-slip-counter").html(19);
        } else {
          $("#outright-slip_c").html(slips);
          $(".outright-slip-counter").html(slips);
        }
      }
    },

    /**
     * removes matchID from betslip
     * @param match_id
     */
    removePick: function (match_id) {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI("removePick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    /**
     * removes matchID from jackpotbetslip
     * @param match_id
     */
    removeJackpotPick: function (match_id) {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);
      this.autoRefreshJackpotUI("removeJackpotPick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    /**
     * removes tournamentID from betslip
     * @param tournament_id
     */
    removeOutrightPick: function (tournament_id) {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.tournament_id === tournament_id) {
              delete bSlip[k];
            }
          }
        });
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshUI("removeOutrightPick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    /**
     * gets betslip
     * @returns {{}}
     */
    getBetSlip: function (stake) {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {

        // console.log('USe this betslip to get odds '+JSON.stringify(bSlip, undefined, 2))

        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odds);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * gets jackpot betslip
     * @returns {{}}
     */
    getJackpotBetSlip: function (stake) {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    getFreeBetSlip: function (stake) {
      var bSlip = this.getObject("fslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * gets outright betslip
     * @returns {{}}
     */
    getOutrightBetSlip: function (stake) {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.tournament_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * clears a  betslip
     */
    clearBetSlip: function () {
      this.saveObject("bslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshUI("clearBetSlip");
      this.EventBus.$emit("event:betslip:hide");
      this.$store.dispatch("setBetSlip", []);
    },

    /**
     * clears a jackpot  betslip
     */
    clearJackpotBetSlip: function () {
      this.saveObject("jslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshJackpotUI("clearJackpotBetSlip");
      this.EventBus.$emit("event:jackpotbetslip:hide");
      this.$store.dispatch("setJackpotBetSlip", []);
    },

    /**
     * clears outright  betslip
     */
    clearOutrightBetSlip: function () {
      this.saveObject("oslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshUI("clearOutrightBetSlip");
      this.EventBus.$emit("event:outrightbetslip:hide");
      this.$store.dispatch("setOutrightBetSlip", []);
    },

    autoRefreshUI: function (callersName) {
      // console.log("autoRefreshUI from " + callersName);
      var vm = this;
      var betsObject = this.getBetSlip(0);
      var bets = betsObject.picks;

      // vm.jQuery(".picked").removeClass("picked");

      $.each(bets, function (matchID, b) {
        var oddsClass = "#" + b.odd_id;
        // vm.jQuery(oddsClass).addClass("picked");

        oddsClass = "#boosted-" + b.odd_id;
        vm.jQuery(oddsClass).addClass("picked");
      });

      // console.log('processed betslips '+JSON.stringify(betsObject, undefined, 2));

      this.saveObject("betslip", betsObject);

      this.$store.dispatch("setBetSlip", betsObject);
      this.$store.dispatch("setHasSuspendedPicks", this.hasSuspendedPicks());

      this.checkSlipCounter(betsObject.total);
    },

    autoRefreshJackpotUI: function (callersName) {
      // console.log("autoJackpotRefreshUI from " + callersName)
      var vm = this;

      var betsObject = this.getJackpotBetSlip(0);
      var bets = betsObject.picks;

      vm.jQuery(".jp-picked").removeClass("jp-picked");

      $.each(bets, function (matchID, b) {
        var oddsClass =
          "#" +
          vm.getOddID(matchID, b.market_id, b.outcome_id, b.specifier, "jpodd");
        // console.log('got picked buttonID '+oddsClass);
        vm.jQuery(oddsClass).addClass("jp-picked");
      });

      this.saveObject("jackpotbetslip", betsObject);

      this.$store.dispatch("setJackpotBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedJackpotPicks",
        this.hasSuspendedJackpotPicks()
      );

      this.checkJackpotSlipCounter(betsObject.total);
    },

    autoRefreshFreebetUI: function (callersName) {
      // console.log("autoJackpotRefreshUI from " + callersName)
      var vm = this;

      var betsObject = this.getFreeBetSlip(30);
      var bets = betsObject.picks;

      vm.jQuery(".picked").removeClass("picked");

      $.each(bets, function (matchID, b) {
        var oddsClass =
          "#" +
          vm.getOddID(matchID, b.market_id, b.outcome_id, b.specifier, "fodd");
        // console.log('got picked buttonID '+oddsClass);
        vm.jQuery(oddsClass).addClass("picked");
      });

      this.saveObject("freebetslip", betsObject);

      this.$store.dispatch("setFreebetBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedFreebetPicks",
        this.hasSuspendedFreebetPicks()
      );

      // this.checkFreebetSlipCounter(betsObject.total);
    },

    autoRefreshOutrightUI: function (callersName) {
      // console.log("autoRefreshOutrightUI from " + callersName);
      var vm = this;
      vm.jQuery(".picked").removeClass("picked");
      var betsObject = this.getOutrightBetSlip(0);
      var bets = betsObject.picks;

      $.each(bets, function (matchID, b) {
        var oddsClass = "#" + b.odd_id;
        vm.jQuery(oddsClass).addClass("picked");
      });

      this.saveObject("outrightbetslip", betsObject);

      this.$store.dispatch("setOutrightBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedOutrightPicks",
        this.hasSuspendedOutrightPicks()
      );

      this.checkOutrightSlipCounter(betsObject.total);
    },

    goBack: function () {
      this.$router.back();
    },

    goHome: function () {
      this.goTo("home");
    },

    setError: function (title, body) {
      /*
      Vue.$toast.open({
          message: body,
          type: 'error',
          duration: 4000,
          dismissible: true,
          position: 'bottom'
          // all of other options may go here
      });
      */
      this.notify(body, "danger");
    },

    setWarning: function (title, body) {
      /*
      Vue.$toast.open({
          message: body,
          type: 'warning',
          duration: 4000,
          dismissible: true,
          position: 'bottom'
          // all of other options may go here
      });
      */

      this.notify(body, "danger");
    },

    setSuccess: function (title, body) {
      /*
      Vue.$toast.open({
          message: body,
          type: 'success',
          duration: 4000,
          dismissible: true,
          position: 'bottom'
          // all of other options may go here
      });

       */

      this.notify(body, "success");
    },

    reset: function () {
      this.$store.dispatch("resetAlerts");
    },

    getSportName: function (sportID) {
      if (sportID === undefined) {
        return "";
      }

      var sports = [
        { sport_id: "1", sport_name: "Soccer" },
        {
          sport_id: "2",
          sport_name: "Basketball",
        },
        { sport_id: "3", sport_name: "Baseball" },
        { sport_id: "4", sport_name: "Ice Hockey" },
        {
          sport_id: "5",
          sport_name: "Tennis",
        },
        { sport_id: "6", sport_name: "Handball" },
        { sport_id: "7", sport_name: "Floorball" },
        {
          sport_id: "9",
          sport_name: "Golf",
        },
        { sport_id: "10", sport_name: "Boxing" },
        { sport_id: "11", sport_name: "Motorsport" },
        {
          sport_id: "12",
          sport_name: "Rugby",
        },
        { sport_id: "13", sport_name: "Aussie Rules" },
        { sport_id: "15", sport_name: "Bandy" },
        {
          sport_id: "16",
          sport_name: "American Football",
        },
        { sport_id: "17", sport_name: "Cycling" },
        { sport_id: "19", sport_name: "Snooker" },
        {
          sport_id: "20",
          sport_name: "Table Tennis",
        },
        { sport_id: "21", sport_name: "Cricket" },
        { sport_id: "22", sport_name: "Darts" },
        {
          sport_id: "23",
          sport_name: "Volleyball",
        },
        { sport_id: "24", sport_name: "Field hockey" },
        { sport_id: "26", sport_name: "Waterpolo" },
        {
          sport_id: "29",
          sport_name: "Futsal",
        },
        { sport_id: "31", sport_name: "Badminton" },
        { sport_id: "32", sport_name: "Bowls" },
        {
          sport_id: "34",
          sport_name: "Beach Volley",
        },
        { sport_id: "37", sport_name: "Squash" },
        { sport_id: "38", sport_name: "Rink Hockey" },
        {
          sport_id: "39",
          sport_name: "Lacrosse",
        },
        { sport_id: "43", sport_name: "Alpine Skiing" },
        { sport_id: "44", sport_name: "Biathlon" },
        {
          sport_id: "46",
          sport_name: "Cross-Country",
        },
        { sport_id: "47", sport_name: "Nordic Combined" },
        {
          sport_id: "48",
          sport_name: "Ski Jumping",
        },
        { sport_id: "49", sport_name: "Snowboard" },
        {
          sport_id: "50",
          sport_name: "Speed Skating",
        },
        { sport_id: "51", sport_name: "Luge" },
        { sport_id: "60", sport_name: "Beach Soccer" },
        {
          sport_id: "61",
          sport_name: "Pesapallo",
        },
        { sport_id: "109", sport_name: "Counter-Strike" },
        {
          sport_id: "110",
          sport_name: "League of Legends",
        },
        { sport_id: "111", sport_name: "Dota 2" },
        { sport_id: "117", sport_name: "MMA" },
        {
          sport_id: "118",
          sport_name: "Call of Duty",
        },
        { sport_id: "121", sport_name: "Overwatch" },
        {
          sport_id: "125",
          sport_name: "Rainbow Six",
        },
        { sport_id: "131", sport_name: "Speedway" },
        {
          sport_id: "147",
          sport_name: "Drag Racing",
        },
        { sport_id: "191", sport_name: "Stock Car Racing" },
      ];
      var sport = [];

      $.each(sports, function (k, v) {
        if (parseInt(v.sport_id) === parseInt(sportID)) {
          //var s = v.sport_name.replaceAll("\"", "").trim();
        }

        sport[v.sport_id] = v;
      });

      if (sport[sportID] === undefined) {
        return "";
      }

      return sport[sportID].sport_name;
    },

    getCountryFlag: function (country_code) {
      var countryCodeLowerCase = this.getApha2Code(country_code).toLowerCase();
      var flag =
        process.env.VUE_APP_FLAGS_BASE_URL +
        (countryCodeLowerCase !== 'unknown' ? countryCodeLowerCase : 'default') +
        ".svg";
      return flag;
    },

    getApha2Code: function (alpha3Code) {
      var r = [
        { alpha2code: "AF", alpha3code: "AFG" },
        {
          alpha2code: "AL",
          alpha3code: "ALB",
        },
        { alpha2code: "DZ", alpha3code: "DZA" },
        {
          alpha2code: "AS",
          alpha3code: "ASM",
        },
        { alpha2code: "AD", alpha3code: "AND" },
        {
          alpha2code: "AO",
          alpha3code: "AGO",
        },
        { alpha2code: "AI", alpha3code: "AIA" },
        {
          alpha2code: "AG",
          alpha3code: "ATG",
        },
        { alpha2code: "AR", alpha3code: "ARG" },
        {
          alpha2code: "AM",
          alpha3code: "ARM",
        },
        { alpha2code: "AW", alpha3code: "ABW" },
        {
          alpha2code: "AU",
          alpha3code: "AUS",
        },
        { alpha2code: "AT", alpha3code: "AUT" },
        {
          alpha2code: "AZ",
          alpha3code: "AZE",
        },
        { alpha2code: "BH", alpha3code: "BHR" },
        {
          alpha2code: "BD",
          alpha3code: "BGD",
        },
        { alpha2code: "BB", alpha3code: "BRB" },
        {
          alpha2code: "BY",
          alpha3code: "BLR",
        },
        { alpha2code: "BE", alpha3code: "BEL" },
        {
          alpha2code: "BZ",
          alpha3code: "BLZ",
        },
        { alpha2code: "BJ", alpha3code: "BEN" },
        {
          alpha2code: "BM",
          alpha3code: "BMU",
        },
        { alpha2code: "BT", alpha3code: "BTN" },
        {
          alpha2code: "BO",
          alpha3code: "BOL",
        },
        { alpha2code: "BQ", alpha3code: "BES" },
        {
          alpha2code: "BA",
          alpha3code: "BIH",
        },
        { alpha2code: "BW", alpha3code: "BWA" },
        {
          alpha2code: "BR",
          alpha3code: "BRA",
        },
        { alpha2code: "IO", alpha3code: "IOT" },
        {
          alpha2code: "VG",
          alpha3code: "VGB",
        },
        { alpha2code: "BN", alpha3code: "BRN" },
        {
          alpha2code: "BG",
          alpha3code: "BGR",
        },
        { alpha2code: "BF", alpha3code: "BFA" },
        {
          alpha2code: "MM",
          alpha3code: "MMR",
        },
        { alpha2code: "BI", alpha3code: "BDI" },
        {
          alpha2code: "KH",
          alpha3code: "KHM",
        },
        { alpha2code: "CM", alpha3code: "CMR" },
        {
          alpha2code: "CA",
          alpha3code: "CAN",
        },
        { alpha2code: "CV", alpha3code: "CPV" },
        {
          alpha2code: "KY",
          alpha3code: "CYM",
        },
        { alpha2code: "CF", alpha3code: "CAF" },
        {
          alpha2code: "ID",
          alpha3code: "TCD",
        },
        { alpha2code: "CL", alpha3code: "CHL" },
        {
          alpha2code: "CN",
          alpha3code: "CHN",
        },
        { alpha2code: "CO", alpha3code: "COL" },
        {
          alpha2code: "KM",
          alpha3code: "COM",
        },
        { alpha2code: "CK", alpha3code: "COK" },
        {
          alpha2code: "CR",
          alpha3code: "CRI",
        },
        { alpha2code: "CI", alpha3code: "CIV" },
        {
          alpha2code: "HR",
          alpha3code: "HRV",
        },
        { alpha2code: "CU", alpha3code: "CUB" },
        {
          alpha2code: "CW",
          alpha3code: "CUW",
        },
        { alpha2code: "CY", alpha3code: "CYP" },
        {
          alpha2code: "CZ",
          alpha3code: "CZE",
        },
        { alpha2code: "DK", alpha3code: "DNK" },
        {
          alpha2code: "DJ",
          alpha3code: "DJI",
        },
        { alpha2code: "DM", alpha3code: "DMA" },
        {
          alpha2code: "DO",
          alpha3code: "DOM",
        },
        { alpha2code: "EC", alpha3code: "ECU" },
        {
          alpha2code: "EG",
          alpha3code: "EGY",
        },
        { alpha2code: "SV", alpha3code: "SLV" },
        {
          alpha2code: "GQ",
          alpha3code: "GNQ",
        },
        { alpha2code: "ER", alpha3code: "ERI" },
        {
          alpha2code: "EE",
          alpha3code: "EST",
        },
        { alpha2code: "ET", alpha3code: "ETH" },
        {
          alpha2code: "FK",
          alpha3code: "FLK",
        },
        { alpha2code: "FO", alpha3code: "FRO" },
        {
          alpha2code: "FM",
          alpha3code: "FSM",
        },
        { alpha2code: "FJ", alpha3code: "FJI" },
        {
          alpha2code: "FI",
          alpha3code: "FIN",
        },
        { alpha2code: "FR", alpha3code: "FRA" },
        {
          alpha2code: "GF",
          alpha3code: "GUF",
        },
        { alpha2code: "PF", alpha3code: "PYF" },
        {
          alpha2code: "GA",
          alpha3code: "GAB",
        },
        { alpha2code: "GE", alpha3code: "GEO" },
        {
          alpha2code: "DE",
          alpha3code: "DEU",
        },
        { alpha2code: "GH", alpha3code: "GHA" },
        {
          alpha2code: "GI",
          alpha3code: "GIB",
        },
        { alpha2code: "GR", alpha3code: "GRC" },
        {
          alpha2code: "GL",
          alpha3code: "GRL",
        },
        { alpha2code: "GD", alpha3code: "GRD" },
        {
          alpha2code: "GP",
          alpha3code: "GLP",
        },
        { alpha2code: "GU", alpha3code: "GUM" },
        {
          alpha2code: "GT",
          alpha3code: "GTM",
        },
        { alpha2code: "GN", alpha3code: "GIN" },
        {
          alpha2code: "GW",
          alpha3code: "GNB",
        },
        { alpha2code: "GY", alpha3code: "GUY" },
        {
          alpha2code: "HT",
          alpha3code: "HTI",
        },
        { alpha2code: "HN", alpha3code: "HND" },
        {
          alpha2code: "HK",
          alpha3code: "HKG",
        },
        { alpha2code: "HU", alpha3code: "HUN" },
        {
          alpha2code: "IS",
          alpha3code: "ISL",
        },
        { alpha2code: "IN", alpha3code: "IND" },
        {
          alpha2code: "ID",
          alpha3code: "IDN",
        },
        { alpha2code: "IR", alpha3code: "IRN" },
        {
          alpha2code: "IQ",
          alpha3code: "IRQ",
        },
        { alpha2code: "IE", alpha3code: "IRL" },
        {
          alpha2code: "IL",
          alpha3code: "ISR",
        },
        { alpha2code: "IT", alpha3code: "ITA" },
        {
          alpha2code: "JM",
          alpha3code: "JAM",
        },
        { alpha2code: "JP", alpha3code: "JPN" },
        {
          alpha2code: "JO",
          alpha3code: "JOR",
        },
        { alpha2code: "KZ", alpha3code: "KAZ" },
        {
          alpha2code: "KE",
          alpha3code: "KEN",
        },
        { alpha2code: "KI", alpha3code: "KIR" },
        {
          alpha2code: "KW",
          alpha3code: "KWT",
        },
        { alpha2code: "KG", alpha3code: "KGZ" },
        {
          alpha2code: "LA",
          alpha3code: "LAO",
        },
        { alpha2code: "LV", alpha3code: "LVA" },
        {
          alpha2code: "LB",
          alpha3code: "LBN",
        },
        { alpha2code: "LS", alpha3code: "LSO" },
        {
          alpha2code: "LR",
          alpha3code: "LBR",
        },
        { alpha2code: "LY", alpha3code: "LBY" },
        {
          alpha2code: "LI",
          alpha3code: "LIE",
        },
        { alpha2code: "LT", alpha3code: "LTU" },
        {
          alpha2code: "LU",
          alpha3code: "LUX",
        },
        { alpha2code: "MO", alpha3code: "MAC" },
        {
          alpha2code: "MK",
          alpha3code: "MKD",
        },
        { alpha2code: "MG", alpha3code: "MDG" },
        {
          alpha2code: "MW",
          alpha3code: "MWI",
        },
        { alpha2code: "MY", alpha3code: "MYS" },
        {
          alpha2code: "MV",
          alpha3code: "MDV",
        },
        { alpha2code: "ML", alpha3code: "MLI" },
        {
          alpha2code: "MT",
          alpha3code: "MLT",
        },
        { alpha2code: "MH", alpha3code: "MHL" },
        {
          alpha2code: "MQ",
          alpha3code: "MTQ",
        },
        { alpha2code: "MR", alpha3code: "MRT" },
        {
          alpha2code: "MU",
          alpha3code: "MUS",
        },
        { alpha2code: "YT", alpha3code: "MYT" },
        {
          alpha2code: "MX",
          alpha3code: "MEX",
        },
        { alpha2code: "MD", alpha3code: "MDA" },
        {
          alpha2code: "MC",
          alpha3code: "MCO",
        },
        { alpha2code: "MN", alpha3code: "MNG" },
        {
          alpha2code: "ME",
          alpha3code: "MNE",
        },
        { alpha2code: "MS", alpha3code: "MSR" },
        {
          alpha2code: "MA",
          alpha3code: "MAR",
        },
        { alpha2code: "MZ", alpha3code: "MOZ" },
        {
          alpha2code: "NA",
          alpha3code: "NAM",
        },
        { alpha2code: "NR", alpha3code: "NRU" },
        {
          alpha2code: "NP",
          alpha3code: "NPL",
        },
        { alpha2code: "NL", alpha3code: "NLD" },
        {
          alpha2code: "AN",
          alpha3code: "ANT",
        },
        { alpha2code: "NC", alpha3code: "NCL" },
        {
          alpha2code: "NZ",
          alpha3code: "NZL",
        },
        { alpha2code: "NI", alpha3code: "NIC" },
        {
          alpha2code: "NE",
          alpha3code: "NER",
        },
        { alpha2code: "NG", alpha3code: "NGA" },
        {
          alpha2code: "NU",
          alpha3code: "NIU",
        },
        { alpha2code: "NF", alpha3code: "NFK" },
        {
          alpha2code: "KP",
          alpha3code: "PRK",
        },
        { alpha2code: "MP", alpha3code: "MNP" },
        {
          alpha2code: "NO",
          alpha3code: "NOR",
        },
        { alpha2code: "OM", alpha3code: "OMN" },
        {
          alpha2code: "PK",
          alpha3code: "PAK",
        },
        { alpha2code: "PW", alpha3code: "PLW" },
        {
          alpha2code: "PS",
          alpha3code: "PSE",
        },
        { alpha2code: "PA", alpha3code: "PAN" },
        {
          alpha2code: "PG",
          alpha3code: "PNG",
        },
        { alpha2code: "PY", alpha3code: "PRY" },
        {
          alpha2code: "PE",
          alpha3code: "PER",
        },
        { alpha2code: "PH", alpha3code: "PHL" },
        {
          alpha2code: "PL",
          alpha3code: "POL",
        },
        { alpha2code: "PT", alpha3code: "PRT" },
        {
          alpha2code: "PR",
          alpha3code: "PRI",
        },
        { alpha2code: "QA", alpha3code: "QAT" },
        {
          alpha2code: "CG",
          alpha3code: "COG",
        },
        { alpha2code: "RE", alpha3code: "REU" },
        {
          alpha2code: "RO",
          alpha3code: "ROU",
        },
        { alpha2code: "RU", alpha3code: "RUS" },
        {
          alpha2code: "RW",
          alpha3code: "RWA",
        },
        { alpha2code: "BL", alpha3code: "BLM" },
        {
          alpha2code: "SH",
          alpha3code: "SHN",
        },
        { alpha2code: "KN", alpha3code: "KNA" },
        {
          alpha2code: "MF",
          alpha3code: "MAF",
        },
        { alpha2code: "PM", alpha3code: "SPM" },
        {
          alpha2code: "VC",
          alpha3code: "VCT",
        },
        { alpha2code: "WS", alpha3code: "WSM" },
        {
          alpha2code: "SM",
          alpha3code: "SMR",
        },
        { alpha2code: "ST", alpha3code: "STP" },
        {
          alpha2code: "SA",
          alpha3code: "SAU",
        },
        { alpha2code: "SN", alpha3code: "SEN" },
        {
          alpha2code: "RS",
          alpha3code: "SRB",
        },
        { alpha2code: "SC", alpha3code: "SYC" },
        {
          alpha2code: "SL",
          alpha3code: "SLE",
        },
        { alpha2code: "SG", alpha3code: "SGP" },
        {
          alpha2code: "SX",
          alpha3code: "SXM",
        },
        { alpha2code: "SK", alpha3code: "SVK" },
        {
          alpha2code: "SI",
          alpha3code: "SVN",
        },
        { alpha2code: "SB", alpha3code: "SLB" },
        {
          alpha2code: "SO",
          alpha3code: "SOM",
        },
        { alpha2code: "ZA", alpha3code: "ZAF" },
        {
          alpha2code: "KR",
          alpha3code: "KOR",
        },
        { alpha2code: "SS", alpha3code: "SSD" },
        {
          alpha2code: "ES",
          alpha3code: "ESP",
        },
        { alpha2code: "LK", alpha3code: "LKA" },
        {
          alpha2code: "LC",
          alpha3code: "LCA",
        },
        { alpha2code: "SD", alpha3code: "SDN" },
        {
          alpha2code: "SR",
          alpha3code: "SUR",
        },
        { alpha2code: "SZ", alpha3code: "SWZ" },
        {
          alpha2code: "SE",
          alpha3code: "SWE",
        },
        { alpha2code: "CH", alpha3code: "CHE" },
        {
          alpha2code: "SY",
          alpha3code: "SYR",
        },
        { alpha2code: "TW", alpha3code: "TWN" },
        {
          alpha2code: "TJ",
          alpha3code: "TJK",
        },
        { alpha2code: "TZ", alpha3code: "TZA" },
        {
          alpha2code: "TH",
          alpha3code: "THA",
        },
        { alpha2code: "BS", alpha3code: "BHS" },
        {
          alpha2code: "GM",
          alpha3code: "GMB",
        },
        { alpha2code: "TL", alpha3code: "TLS" },
        {
          alpha2code: "TG",
          alpha3code: "TGO",
        },
        { alpha2code: "TK", alpha3code: "TKL" },
        {
          alpha2code: "TO",
          alpha3code: "TON",
        },
        { alpha2code: "TT", alpha3code: "TTO" },
        {
          alpha2code: "TN",
          alpha3code: "TUN",
        },
        { alpha2code: "TR", alpha3code: "TUR" },
        {
          alpha2code: "TM",
          alpha3code: "TKM",
        },
        { alpha2code: "TC", alpha3code: "TCA" },
        {
          alpha2code: "TV",
          alpha3code: "TUV",
        },
        { alpha2code: "UG", alpha3code: "UGA" },
        {
          alpha2code: "UA",
          alpha3code: "UKR",
        },
        { alpha2code: "AE", alpha3code: "ARE" },
        {
          alpha2code: "GB",
          alpha3code: "GBR",
        },
        { alpha2code: "GB", alpha3code: "ENG" },
        {
          alpha2code: "US",
          alpha3code: "USA",
        },
        { alpha2code: "UY", alpha3code: "URY" },
        {
          alpha2code: "VI",
          alpha3code: "VIR",
        },
        { alpha2code: "UZ", alpha3code: "UZB" },
        {
          alpha2code: "VU",
          alpha3code: "VUT",
        },
        { alpha2code: "VA", alpha3code: "VAT" },
        {
          alpha2code: "VE",
          alpha3code: "VEN",
        },
        { alpha2code: "VN", alpha3code: "VNM" },
        {
          alpha2code: "WF",
          alpha3code: "WLF",
        },
        { alpha2code: "YE", alpha3code: "YEM" },
        {
          alpha2code: "ZM",
          alpha3code: "ZMB",
        },
        { alpha2code: "ZW", alpha3code: "ZWE" },
      ];
      var n = 0;
      var max = r.length;

      if (alpha3Code === "ENG") {
        return "GB";
      }

      if (alpha3Code === "NIR") {
        return "GB-NIR";
      }

      if (alpha3Code === "SCO") {
        return "GB-SCT";
      }

      while (n < max) {
        var o = r[n];
        if (o.alpha3code === alpha3Code) {
          return o.alpha2code;
        }

        n++;
      }

      return "WORLD";
    },

    logout: function () {
      this.closeMenu();
      // set profile to false
      this.removeProfile();
      // reset betslip
      this.clearBetSlip();
      // reset alerts
      this.reset();
      // if (this.$route.path !== "/home") this.$router.push("/home")
      // this.$router.push({ name: "", params: {} });
      window.location.href = "/"
    },

    getURLParameters: function () {
      var query = window.location.search.substring(1);

      var vars = query.split("&");

      var query_string = {};

      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
          // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
          var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
          query_string[pair[0]] = arr;
          // If third or later entry with this name
        } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
      }

      return query_string;
    },
    goTo: function (Name) {
      try {
        //this.dismiss();
        this.closeMenu();

        Name = Name.toLowerCase();

        if (this.$store.state.current_page === Name) return;

        this.$router.push({ name: Name });
      } catch (e) {
        // console.log(e.message);
      }
    },
    sortObject: function (obj) {
      return Object.keys(obj)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        })
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    notify: function (message, type) {
      //no-undef
      notif(message, type);
    },
    closeMenu: function () {
      var el = document.getElementById("menu-close");
      if (el) {
        el.click();
      }
    },
    formatStake: function (num) {
      if (
        num === false ||
        isNaN(parseFloat(num)) ||
        isNaN(num) ||
        num === undefined ||
        num === "" ||
        num === null
      ) {
        return 0;
      }

      num = parseFloat(num).toFixed(0);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
    isLoggedInCasino: function () {
      var p = this.getAuth();
      if (!p) {
        return false;
      }

      return true;
    },
    casinoLaunchUrl: function (launch) {
      if (launch === "") {
        this.goTo("home")
      } else {
        return launch
      }

    },
    launchCasino() {
      return this.casinoLaunchUrl();
    },

    //generates gameurl for all casino games


  },
});

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});


var v = new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

if (!v) {
  window.location.href = "https://lite.tucheze.com";
}
import AppHome from './components/AppHome'
import BustaPesa from "./components/BustaPesa";

const Home = () => import('./components/Home')
const Sport = () => import('./components/Sport')

const FixturesBySport = () => import('./components/FixturesBySport')
const Esport = () => import('./components/Esport')
const Print = () => import('./components/Print')
const PrintGame = () => import('./components/PrintGame')
const Tips = () => import('./components/Tips')
const Sharebet = () => import('./components/Sharebet')
const Offers = () => import('./components/Offers')
const Live = () => import('./components/Live')
const MobileApp = () => import("./components/MobileApp");
const Match = () => import('./components/Match')
const AviatorLogin = () => import('./components/AviatorLogin')
const Login = () => import('./components/Login')
const Join = () => import('./components/Join')
const Reset = () => import('./components/Reset')
const Deposit = () => import('./components/Deposit')
const DepositHowTo = () => import('./components/DepositHowTo')
const Setting = () => import('./components/Setting')
const Bets = () => import('./components/Bets')
const Terms = () => import('./components/Terms')
const Faq = () => import('./components/Faq')
const Freebet = () => import("@/components/Freebet");
const Search = () => import("./components/Search");
const Countries = () => import("@/components/Countries");
const Outright = () => import("@/components/outright/Index");
const Today = () => import("@/components/Today");
const Tomorrow = () => import("@/components/Tomorrow");
const Upcoming = () => import("@/components/Upcoming");
const Share = () => import("@/components/Share");
const Invite = () => import("@/components/Invite");
const Help = () => import("./components/Help");
const Responsible = () => import("./components/Responsible");
const Booklet = () => import("./components/Booklet");
const Jackpot = () => import("./components/jackpot/Jackpot");
const Results = () => import("./components/Results");
const Livescore = () => import("./components/Livescore");
const Profile = () => import("./components/Profile");
const Gift = () => import("./components/Gift");
const SelfExclusion = () => import("./components/SelfExclusion");
const FormulaOne = () => import("./components/FormulaOne");

//casino menus
const CasinoGames = () => import("./components/casino/CasinoGames");
const Casino = () => import("./components/casino/Casino");
const Virtuals = () => import("./components/casino/Virtuals");
const LiveCasino = () => import("./components/casino/Live");
const Fastballs = () => import("./components/casino/Fastballs");
const Smartsoft = () => import("./components/casino/Smartsoft");
const Jetsafi = () => import("./components/casino/Jetsafi");
const Booming = () => import("./components/casino/Booming");
const Stp = () => import("./components/casino/Stp");
const Onlyplay = () => import("./components/casino/Onlyplay");

//
const Rocketman = () => import("./components/casino/Rocketman");
const MonkeyBizniz = () => import("./components/casino/MonkeyBizniz");
const Aviator = () => import("./components/casino/Aviator");
const Comet = () => import("./components/casino/Comet");
const JetX = () => import("./components/casino/JetX");
const FootballX = () => import("./components/casino/FootballX");
const StreetDice = () => import("./components/casino/StreetDice");
const Spin2Win = () => import("./components/casino/Spin2Win");
const AztecGems = () => import("./components/casino/AztecGems");
const Plinko = () => import("./components/casino/Plinko");
export const routes = [
    {
        path: '/',
        name: 'sports',
        component: Sport,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/'
        }
    },
    {
        path: '/crash',
        name: 'home',
        component: Home,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/a'
        }
    },
    {
        path: "/sports/football",
        name: "football",
        component: Home,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/a'
        }
    },
    {
        path: '/sport/:sport',
        name: 'sport',
        component: Sport,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/sport/:sport'
        }
    },
    {
        path: '/a',
        name: 'apphome',
        component: AppHome,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/a'
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/search'
        }
    },
    {
        path: '/sports/:sport_name/:sport_id',
        name: 'fixturesbysport',
        component: FixturesBySport,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/sports/:sport_name/:sport_id'
        }
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/live'
        }
    },
    {
        path: '/freebet',
        name: 'freebet',
        component: Freebet,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/'
        }
    },
    {
        path: '/today',
        name: 'today',
        component: Today,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/today'
        }
    },

    {
        path: '/tomorrow',
        name: 'tomorrow',
        component: Tomorrow,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/tomorrow'
        }
    },

    {
        path: '/upcoming',
        name: 'upcoming',
        component: Upcoming,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/upcoming'
        }
    },
    {
        path: '/esport',
        name: 'esport',
        component: Esport,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/esport'
        }
    },
    {
        path: "/match/:status/:game_id/:name",
        name: "match",
        component: Match,
    },
    {
        path: '/share/:code',
        name: 'share',
        component: Share,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com//share/:code'
        }
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/invite/:code'
        }
    },
    {
        path: '/join',
        name: 'join',
        component: Join,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/join'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/login'
        }
    },
    {
        path: '/aviatorlogin',
        name: 'aviatorlogin',
        component: AviatorLogin,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/login'
        }
    },
    {
        path: '/print',
        name: 'print',
        component: Print,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/print'
        }
    },

    {
        path: '/print-game',
        name: 'print-game',
        component: PrintGame,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/print-game'
        }
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/deposit'
        }
    },
    {
        path: '/countries',
        name: 'country',
        component: Countries,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/countries'
        }
    },
    {
        path: '/outrights/:type',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/outrights/:type'
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/setting'
        }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Setting,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/my-account'
        }
    },
    {
        path: '/my-bets',
        name: 'bets',
        component: Bets,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/my-bets'
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/terms'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/faq'
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gift,
        meta: {
            title: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/gifts'
        }
    },
    {
        path: '/selfexclusion',
        name: 'selfexclusion',
        component: SelfExclusion,
        meta: {
            title: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/gifts'
        }
    },
    {
        path: '/deposit-how-to',
        name: 'deposit-how-to',
        component: DepositHowTo,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/deposit-how-to'
        }
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: MobileApp,
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/howto'
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/responsible-gaming'
        }
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/reset/:msisdn/:code'
        }
    },
    {
        path: '/booklet',
        name: 'booklet',
        component: Booklet,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/booklet'
        }
    },
    {
        path: '/jackpot-games',
        name: 'jackpot',
        component: Jackpot,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/jackpot-games'
        }
    },
    {
        path: '/tips',
        name: 'tips',
        component: Tips,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/tips'
        }
    },
    {
        path: '/sharebet',
        name: 'sharebet',
        component: Sharebet,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/sharebet'
        }
    },
    {
        path: '/tucheze',
        name: 'tucheze',
        component: BustaPesa,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/tucheze'
        }
    },
    {
        path: '/formula-1',
        name: 'formula1',
        component: FormulaOne,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/formula-1'
        }
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers,
        meta: {
            title: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Tucheze.com Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Tucheze.com Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/offers'
        }
    },
    {
        path: '/results',
        name: 'results',
        component: Results,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/results'
        }
    },
    {
        path: '/livescore',
        name: 'livescore',
        component: Livescore,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/livescore'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/profile'
        }
    },
    {
        path: '*',
        name: '404',
        component: Home,
        meta: {
            title: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'Tucheze.com - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Tucheze.com.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/404'
        }
    },
    //    casino games
    {
        path: '/casino',
        name: 'casino',
        component: Casino,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/casino'
        }
    },
    {
        path: '/virtuals',
        name: 'virtuals',
        component: Virtuals,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/virtuals'
        }
    },
    {
        path: '/casino/live',
        name: 'livecasino',
        component: LiveCasino,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/casino/live'
        }
    },
    {
        path: '/casino-games',
        name: 'casinogames',
        component: CasinoGames,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/casino-games'
        }
    },
    {
        path: '/rocketman',
        name: 'rocketman',
        component: Rocketman,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/rocketman'
        }
    },
    {
        path: '/monkeybizniz',
        name: 'monkeybizniz',
        component: MonkeyBizniz,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/rocketman'
        }
    },
    {
        path: '/aviator',
        name: 'aviator',
        component: Aviator,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/aviator'
        }
    },
    {
        path: '/comet',
        name: 'comet',
        component: Comet,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/comet'
        }
    },
    {
        path: '/jetx',
        name: 'jetx',
        component: JetX,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/jetx'
        }
    },
    {
        path: '/footballx',
        name: 'footballx',
        component: FootballX,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/footballx'
        }
    },
    {
        path: '/streetdice',
        name: 'streetdice',
        component: StreetDice,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/streetdice'
        }
    },
    {
        path: '/spin2win',
        name: 'spin2win',
        component: Spin2Win,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/spin2win'
        }
    },
    {
        path: '/aztecgems',
        name: 'aztecgems',
        component: AztecGems,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/aztecgems'
        }
    },
    {
        path: '/plinko',
        name: 'plinko',
        component: Plinko,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/plinko'
        }
    },
    {
        path: '/fastballs',
        name: 'fastballs',
        component: Fastballs,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/fastballs'
        }
    },
    {
        path: '/smartsoft',
        name: 'smartsoft',
        component: Smartsoft,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/smartsoft'
        }
    },
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/jetsafi'
        }
    },
    {
        path: '/stp',
        name: 'stp',
        component: Stp,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/stp'
        }
    },
    {
        path: '/booming',
        name: 'Booming',
        component: Booming,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/booming'
        }
    },
    {
        path: '/onlyplay',
        name: 'Onlyplay',
        component: Onlyplay,
        meta: {
            title: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Tucheze.com Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://tucheze.com/android-chrome-512x512.png',
            ogUrl: 'https://tucheze.com/onlyplay'
        }
    },

];